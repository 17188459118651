import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  foodGallery,
  kitanodon1,
  kitanodon2,
  kitanodon3,
  kitanodon4,
  kitanodon5,
  kitanodon6,
  kitanodon7,
  kitanodon8,
  kitanodon9,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView mb="20px">
      <MobileScreen>
        <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={kitanodon1}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={kitanodon2}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={kitanodon3}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={kitanodon4}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={kitanodon5}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={kitanodon6}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={kitanodon7}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={kitanodon8}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
          <CFImage
            src={kitanodon9}
            style={{ objectFit: 'contain' }}
            ph="5px"
            pv="10px"
          />
        </Slider>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="30px auto" w="85%">
          <CFImage src={foodGallery} alt="Food Gallery" w="100%" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
